import React from 'react'
import BaseComponent from './BaseComponent'
import { NavLink } from 'react-router-dom'
import DatePicker from "react-datepicker"
import { format, isAfter, isEqual, parseISO, isValid, differenceInSeconds} from 'date-fns';

export default class extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = {dateFrom: null, dateTo: null, email: null, id: null, reqDateFrom: null, reqDateTo: null, dateToError: false, dateFromError: false, lastNotifyTime: null};
    this._bind(
      'handleReportSend',
      'onSuccess',
      'validateDate'
    )
  }

  validateDate(dateFrom, dateTo) {
    if (isValid(parseISO(dateFrom)) && isValid(parseISO(dateTo))) {
      const isDateAfter = isAfter(parseISO(dateTo), parseISO(dateFrom));
      const isDateEqual = isEqual(parseISO(dateTo), parseISO(dateFrom));

       if (!isDateAfter && !isDateEqual) {
        this.setState({dateToError: true, dateFromError: true})
        if (!this.state.lastNotifyTime) {
          this.setState({lastNotifyTime: new Date()});
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.manage.cyclicalReports.errors.invalidDateRange}}), true);
        }
        if(differenceInSeconds(new Date(), this.state.lastNotifyTime) > 3){
          this.setState({lastNotifyTime: new Date()});
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.manage.cyclicalReports.errors.invalidDateRange}}), true);
        }

        return false;
       }
    }
    if(!dateFrom && dateTo) {

      this.setState({dateToError: false, dateFromError: true});
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.manage.cyclicalReports.errors.dateNotSelected}}), true);

      return false;
    }
    if(dateFrom && !dateTo) {

      this.setState({dateFromError: false, dateToError: true})
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.manage.cyclicalReports.errors.dateNotSelected}}), true)

      return false;
    }

    this.setState({dateToError: false, dateFromError: false})
    return true;
  }

  shortFetch(method, endpoint, data, success) {
    fetch(this._apiBase + endpoint + `?partnerId=${this.state.id}${this.state.reqDateFrom ? '&dateFrom='+this.state.reqDateFrom : ''}${this.state.reqDateTo ? '&dateTo='+this.state.reqDateTo : ''}${this.state.email ? '&email='+this.state.email : ''}`, {
      method: method,
      withCredentials: true,
      credentials: "include",
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': 'application/json',
        'Accept-Charset': 'utf-8',
        'Accept-Language': this.props.lang,
        'X-api-key': '46B9D48A125733B4C9226CE570007',
        'X-business-token': localStorage.getItem("accessToken")
      },
      body: null
    })
    .then((response) => {
      if (response.status === 401) {
        this.props.checkSession()
      } else if (response.status === 404 || response.status >= 500) {
        this.props.history.replace("/error/"+response.status+"?redirect="+this.props.location.pathname)
      } else {
        return response.json()
      }
    })
    .then((result) => {
      
      if (result.answer === "OK") {
        success(result)
      } else if(result.answer === "ERROR" && result.result.errors.length > 0) {
        for (var i = 0; i < result.result.errors.length; i++) {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.result.errors[i].msg}}), true)
        }
      } else if (result === true) {
         success(result);
      }
      else {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError}}), true)
      }
      this.setState({ preloader: false })
    }, (_error) => {
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError }}), true)
    })
  }
  onSuccess() {
    window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Raport cykliczny został wysłany" }}), true)
  }

  handleReportSend(data) {
    this.shortFetch('POST', 'report/cyclical', data, this.onSuccess)
  }
  
  UNSAFE_componentWillMount() {
    if (!this.props.match.params.page) {
      this.props.history.replace(this.props.lang === "pl" ? "/reports/cr" : "/en/reports/cr")
    }
  }


  render () {
    const page = this.props.data.manage
    return (
      (
        <div className="box">
          <div className="tabs">
            <NavLink to={ this.props.lang === "pl" ? '/reports/r' : '/en/reports/r' }>{ page.reportsName }</NavLink>
            { this.props.user.userType === "USER" && this.props.user.cardUser === false ? null : <span className="sep">|</span> }
            { this.props.user.userType === "USER" && this.props.user.cardUser === false ? null : <NavLink to={ this.props.lang === "pl" ? '/reports/i' : '/en/reports/i' }>{ page.invoicesName }</NavLink> }
            { this.props.user.userType === "SUPER" ? (<><span className="sep">|</span> <NavLink to={ this.props.lang === "pl" ? '/reports/cr' : '/en/reports/cr' }>{ page.cyclicalReports.tabName }</NavLink></>) : null}
          </div>
          <div className="table-outer cyclical-reports">
            <div className="top">
              <h3>{ page.cyclicalReports.tabName} </h3>
            </div>
            <form onSubmit={(e) => {
              e.preventDefault();
              const isValid = this.validateDate(this.state.reqDateFrom, this.state.reqDateTo);
              if (isValid) {
                this.handleReportSend({
                  dateFrom: this.state.reqDateFrom, 
                  dateTo: this.state.reqDateTo, 
                  partnerId: this.state.id, 
                  email: this.state.email
                });
              }
            }}>
              <div className='form'>
                <div className='input'>
                  <label htmlFor='cr-id'>{page.cyclicalReports.partnerId}</label> 
                  <input type='number' aria-label={page.cyclicalReports.companyPlaceholder} name='cr-id' required pattern="^[0-9]*$" placeholder={page.cyclicalReports.companyPlaceholder} onChange={(e)=> {
                    if (e.target.value.match(/^[0-9]*$/)) {
                      this.setState({disabled: false})
                      this.setState({id: e.target.value})
                    }
                  }}></input>
                </div>
                <div className='input'>
                  <label htmlFor='cr-email'>{page.cyclicalReports.emailLabel}</label> 
                  <input type='email' aria-label={page.cyclicalReports.emailPlaceholder}  placeholder={page.cyclicalReports.emailPlaceholder} name='cr-email' pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" onChange={(e)=> {
                    if (e.target.value.match(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)) {
                      this.setState({email: e.target.value})
                    }
                  }}></input>
                </div>
                <div className='input'>
                  <label className='date' >{page.cyclicalReports.dateRangeLabel}</label>
                    <div>
                      <label className='date'>{page.cyclicalReports.from}</label>
                      <DatePicker
                        selected={this.state.dateFrom}
                        onChange={(date) => {
                          if (date) {
                            this.setState({reqDateFrom: format(date, 'yyyy-MM-dd'), dateFrom: date});
                            this.validateDate(format(date, 'yyyy-MM-dd'), this.state.reqDateTo)
                          }
                        }}
                        onChangeRaw={(event)=> {
                          if (event.target.value === '') {
                            this.setState({reqDateFrom: null, dateFrom: null});
                            this.validateDate(null, this.state.reqDateTo)
                          }
                        }}
                        aria-label={page.cyclicalReports.datePlaceholder} 
                        title="Wybierz datę"
                        locale="pl"
                        dateFormat="dd/MM/yyyy"
                        id="cyclicalReportsDPFrom"
                        placeholderText={page.cyclicalReports.datePlaceholder} 
                        maxDate={new Date()}
                        className={this.state.dateFromError ? 'input-error': ''}
                      />
                    </div>
                    <div>
                    <label className='date'>{page.cyclicalReports.to}</label>
                      <DatePicker
                        selected={this.state.dateTo}
                        onChange={(date) => {
                          if (date) {
                            this.setState({reqDateTo: format(date, 'yyyy-MM-dd'), dateTo: date})
                            this.validateDate(this.state.reqDateFrom, format(date, 'yyyy-MM-dd'))
                          }
                        }}
                        onChangeRaw={(event)=> {
                          if (event.target.value === '') {
                            this.setState({reqDateTo: null, dateTo: null});
                            this.validateDate(this.state.reqDateFrom, null)
                          }
                        }}
                        locale="pl"
                        aria-label={page.cyclicalReports.datePlaceholder}  
                        title="Wybierz datę"
                        dateFormat="dd/MM/yyyy"
                        id="cyclicalReportsDPTo"
                        placeholderText={page.cyclicalReports.datePlaceholder} 
                        maxDate={new Date()}
                        className={this.state.dateToError ? 'input-error': ''}
                      />
                    </div>
                </div>
              </div>
              <div className="buttons">
              <button className="btn yellow small" id="button" type='submit'>
                <span>{page.cyclicalReports.buttonText}</span>
              </button>
            </div>
            </form>
          </div>
        </div>
      )
    )
  }
}
